<template>
  <div class="subscribe-page common-page">
    <Nav />
    <div class="page-top">
      <h1 class="title">订阅浩亭电子报</h1>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
          fill="white"
          fill-opacity="0.7"
        />
      </svg>
    </div>
    <!-- <div class="common-card-wrap">
      <div class="custom-form">
        <van-form @submit="onSubmit">
          <van-field
            v-model="form.regionName"
            name="regionName"
            is-link
            readonly
            placeholder="国家/地区*"
            @click="show = true"
            :rules="[{ required: true, message: '请选择国家/地区' }]"
          />
          <van-popup v-model="show" round position="bottom">
            <van-cascader
              v-model="form.regionId"
              title="请选择国家/地区"
              :options="options"
              @close="show = false"
              @finish="onFinish"
              active-color="#fc0"
            />
          </van-popup>
          <van-field
            v-model="form.email"
            name="email"
            placeholder="电子邮箱*"
            :rules="[
              { required: true, message: '请输入电子邮箱名称' },
              {
                pattern:
                  /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                message: '邮箱格式错误！',
              },
            ]"
          />
          <van-field
            v-model="form.surname"
            name="surname"
            placeholder="姓*"
            :rules="[{ required: true, message: '请输入姓' }]"
          />
          <van-field
            v-model="form.name"
            name="name"
            placeholder="名*"
            :rules="[{ required: true, message: '请输入名' }]"
          />
          <van-field
            v-model="form.company"
            name="company"
            placeholder="公司名称*"
            :rules="[{ required: true, message: '请输入公司名称' }]"
          />

          <van-field name="agree" class="radio-cell radio-cell-long">
            <template #input>
              <van-radio-group v-model="form.agree" direction="horizontal">
                <van-radio :name="true"
                  >我已阅读并同意 <span class="bold">《隐私政策》</span>和<span
                    class="bold"
                    >《一般使用条款》 。</span
                  ></van-radio
                >
              </van-radio-group>
            </template>
          </van-field>

          <div class="btn-wrap">
            <van-button round block type="info" native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
    </div> -->
    <div class="iframe-box">
      <div v-if="isLoading" class="laoding-box" >
        <van-loading color="#fc0">加载中...</van-loading>
      </div>
      <iframe :src="formUrl" @load="onLoad" width="100%" height="100%"></iframe> 
    </div>
    <div class="common-bottom-empty"></div>
  </div>
</template>
<script>
import Nav from "../../component/nav.vue";
import { Form, Field, RadioGroup, Radio, Button, Cascader, Popup, Loading } from "vant";
export default {
  name: "Subscribe",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button,
    [Cascader.name]: Cascader,
    [Popup.name]: Popup,
    [Loading.name]: Loading,
    Nav
  },
  data() {
    return {
      form: {
        company: "",
        surname: "",
        name: "",
        email: "",
        agree: false,
        regionName: '',
        regionId: ''
      },
      show: false,
      fieldValue: "",
      cascaderValue: "",
      isLoading: true,
      formUrl: 'https://b2b.harting.com.cn/b2bchat/next_cockpit_harting/popup.html?lang=CN-ZH-HANS&cat=CN_Chat',
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [
        {
          text: "浙江省",
          value: "330000",
          children: [{ text: "杭州市", value: "330100" }],
        },
        {
          text: "江苏省",
          value: "320000",
          children: [{ text: "南京市", value: "320100" }],
        },
      ],
    };
  },
  methods: {
    onSubmit(form) {
      if (!form.agree) {
        this.$notify({
          message: "请勾选政策和条款",
          color: "#000",
          background: "#fc0",
        });
      }
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.form.regionName = selectedOptions.map((option) => option.text).join('/');
    },
    onLoad() {
      this.isLoading = false;  
    }
  },
};
</script>
<style lang="less" scoped>
.subscribe-page {
  .page-top {
    padding: 92px 32px 32px 32px;
    .title {
      margin-bottom: 8px;
      color: #212027;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 48px */
    }
  }
  .common-card-wrap {
    box-sizing: border-box;
    min-height: calc(100vh - 260px);
    background: @theme-color;
  }
  .common-bottom-empty{
    background: @theme-color;
  }
  .iframe-box{
    position: relative;
    width: 100%;
    height: calc(100vh - 150px - 118px);
    .laoding-box{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%; -50%);
    }
  }
}
</style>
